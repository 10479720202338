<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
    <div class="designmode" v-if="designmode != 'no'">
      <button
        class="btn btn-danger"
        style="border-radius: 0px"
        v-if="designmode"
        @click="designmode = !designmode"
      >
        📝 ايقاف تحرير الصفحة
      </button>
      <button
        class="btn btn-success"
        style="border-radius: 0px"
        v-if="!designmode"
        @click="designmode = !designmode"
      >
        📝 تفعيل تحرير الصفحة
      </button>
    </div>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";
const axios = require("axios");
const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  created() {
    var _g = this;
    var jwt = localStorage.getItem("jwt");
    if (jwt == null && window.location.pathname != "/login") {
      window.location = "/login";
    } else {
      if (window.location.pathname == "/login") {
        if (jwt != null) {
          window.location = "/";
        }
      } else {
        axios
          .post(localStorage.getItem("api") + "/auth/check", {
            jwt: localStorage.getItem("jwt"),
          })
          .then(function (e) {
            if (e.data.status == 200) {
              localStorage.removeItem("jwt");
              window.location = "/";
            } else {
              if (
                e.data.status == 300 &&
                window.location.pathname != "/ended"
              ) {
                window.location = "/ended";
              }
              if (
                e.data.status == 400 &&
                window.location.pathname != "/limit"
              ) {
                window.location = "/limit";
              }
              if (e.data.status == 100) {
                if (localStorage.getItem("user") == null) {
                  setTimeout(() => {
                    location.reload();
                  }, 100);
                }
                localStorage.setItem("user", JSON.stringify(e.data.response));
                axios
                  .post(localStorage.getItem("api") + "/teachers/info", {
                    jwt: localStorage.getItem("jwt"),
                  })
                  .then(function (r) {
                    if (r.data.status == 200) {
                      _g.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "حدث خطأ JWT",
                          icon: "WarningIcon",
                          variant: "danger",
                        },
                      });
                    } else {
                      if (!r.data.response) {
                        axios.post(
                          localStorage.getItem("api") + "/teachers/save-info",
                          {
                            jwt: localStorage.getItem("jwt"),
                            school_title: "",
                            number: "",
                            admin: "",
                            direct_boss: "",
                            sms_portal: "",
                            sms_username: "",
                            sms_password: "",
                            sms_sendername: "",
                          }
                        );
                      }
                    }
                  });
                if (window.location.pathname == "/ended") {
                  window.location = "/";
                }
              }
            }
          })
          .catch(function () {
            localStorage.removeItem("jwt");
            window.location = "/";
          });
      }
    }
    setInterval(() => {
      if (localStorage.getItem("per") != null) {
        if (
          !JSON.parse(localStorage.getItem("per")).includes("scan") &&
          !JSON.parse(localStorage.getItem("per")).includes(
            "teachers_scan_barcode"
          ) &&
          window.location.href.includes("scan") &&
          window.location.pathname != "/" &&
          window.location.pathname != "/logout" &&
          window.location.pathname != "/login"
        ) {
          window.location = "/";
          alert("ليس لديك صلاحيات");
        }
        if (
          !JSON.parse(localStorage.getItem("per")).includes("reports") &&
          window.location.href.includes("reports") &&
          window.location.pathname != "/" &&
          window.location.pathname != "/logout" &&
          window.location.pathname != "/login"
        ) {
          window.location = "/";
          alert("ليس لديك صلاحيات");
        }
        if (
          !JSON.parse(localStorage.getItem("per")).includes("bulk") &&
          window.location.href.includes("bulk") &&
          window.location.pathname != "/" &&
          window.location.pathname != "/logout" &&
          window.location.pathname != "/login"
        ) {
          window.location = "/";
          alert("ليس لديك صلاحيات");
        }
        if (
          !window.location.href.includes("reports") &&
          !window.location.href.includes("scan") &&
          window.location.pathname != "/" &&
          window.location.pathname != "/logout" &&
          window.location.pathname != "/fingerprint" &&
          window.location.pathname != "/login"
        ) {
          if (!JSON.parse(localStorage.getItem("per")).includes("all")) {
            window.location = "/";
            alert("ليس لديك صلاحيات..");
          }
        }
      }
    }, 1000);
    var g = this;
    setInterval(() => {
      if (
        window.location.href.includes("ask-late-between") ||
        window.location.href.includes("ask-late") ||
        window.location.href.includes("ask-ghiab")||
        window.location.href.includes("aks/")
      ) {
        if (g.designmode == "no") {
          g.designmode = false;
        } else {
          if (g.designmode == true) {
            document.designMode = "on";
          } else {
            document.designMode = "off";
          }
        }
      } else {
        document.designMode = "off";
        g.designmode = "no";
      }
    }, 500);
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });
    return {
      skinClasses,
    };
  },
  data() {
    return {
      designmode: "no",
    };
  },
};
</script>
<style>
.designmode {
  position: fixed;
  top: 100px;
  right: 0px;
}
@media print{
  .designmode{
    display: none;
  }
}
</style>