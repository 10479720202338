import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [{
        path: '/',
        name: 'home',
        component: () =>
            import('@/views/Home.vue'),
        meta: {
            pageTitle: 'الرئيسية',
            breadcrumb: [{
                text: 'الرئيسية',
                active: true,
            },],
        },
    },
    {
        path: '/teachers',
        name: 'teachers',
        component: () =>
            import('@/views/teachers/index.vue'),
        meta: {
            pageTitle: 'التحكم بالمعلمين',
            breadcrumb: [{
                text: 'التحكم بالمعلمين',
                active: true,
            },],
        },
    },
    {
        path: '/shifts',
        name: 'shifts',
        component: () =>
            import('@/views/shifts.vue'),
    },
    {
        path: '/teachers/add',
        name: 'addTeacher',
        component: () =>
            import('@/views/teachers/add.vue'),
        meta: {
            pageTitle: 'إضافة معلم جديد',
            breadcrumb: [{
                text: 'إضافة معلم جديد',
                active: true,
            },],
        },
    },
    {
        path: '/teachers/import',
        name: 'importTeacher',
        component: () =>
            import('@/views/teachers/import.vue'),
        meta: {
            pageTitle: 'استيراد المعلمين',
            breadcrumb: [{
                text: 'استيراد المعلمين',
                active: true,
            },],
        },
    },
    {
        path: '/scan',
        name: 'scan',
        component: () =>
            import('@/views/scan.vue'),
        meta: {
            pageTitle: 'شاشة الفحص',
            breadcrumb: [{
                text: 'شاشة الفحص',
                active: true,
            },],
        },
    },
    {
        path: '/scan/bulk',
        name: 'bulk',
        component: () =>
            import('@/views/scan/bulk.vue'),
        meta: {
            pageTitle: 'شاشة الفحص',
            breadcrumb: [{
                text: 'التحضير الجماعي',
                active: true,
            },],
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: () =>
            import('@/views/settings.vue'),
        meta: {
            pageTitle: 'اعدادات المعلمين',
            breadcrumb: [{
                text: 'اعدادات المعلمين',
                active: true,
            },],
        },
    },
    {
        path: '/settings/create',
        name: 'settingsCreate',
        component: () =>
            import('@/views/settings-create.vue'),
        meta: {
            pageTitle: 'انشاء مجموعة اعدادات',
            breadcrumb: [{
                text: 'انشاء مجموعة اعدادات',
                active: true,
            },],
        },
    },
    {
        path: '/settings/edit/:code',
        name: 'settingsEdit',
        component: () =>
            import('@/views/settings-edit.vue'),
        meta: {
            pageTitle: 'تعديل مجموعة اعدادات',
            breadcrumb: [{
                text: 'تعديل مجموعة اعدادات',
                active: true,
            },],
        },
    },
    {
        path: '/scan/manual',
        name: 'scanManual',
        component: () =>
            import('@/views/scan/manual.vue'),
        meta: {
            pageTitle: 'صفحة الفحص',
            breadcrumb: [{
                text: 'صفحة الفحص',
                active: true,
            },],
        },
    },
    {
        path: '/fingerprint',
        name: 'fingerprint',
        component: () =>
            import('@/views/fingerprint.vue'),
        meta: {
            pageTitle: 'أجهزة البصمة',
            breadcrumb: [{
                text: 'أجهزة البصمة',
                active: true,
            },],
        },
    },
    {
        path: '/fingerprint/add',
        name: 'fingerprintAdd',
        component: () =>
            import('@/views/fingerprint-add.vue'),
        meta: {
            pageTitle: 'اضافة جهاز بصمة جديد',
            breadcrumb: [{
                text: 'اضافة جهاز بصمة جديد',
                active: true,
            },],
        },
    },
    {
        path: '/fingerprint/device/:id',
        name: 'fingerprintDevice',
        component: () =>
            import('@/views/fingerprint-device.vue'),
        meta: {
            pageTitle: 'تفاصيل الجهاز',
            breadcrumb: [{
                text: 'تفاصيل الجهاز',
                active: true,
            },],
        },
    },
    {
        path: '/fingerprint/edit/:id',
        name: 'fingerprintEdit',
        component: () =>
            import('@/views/fingerprint-edit.vue'),
        meta: {
            pageTitle: 'تعديل الجهاز',
            breadcrumb: [{
                text: 'تعديل الجهاز',
                active: true,
            },],
        },
    },
    {
        path: '/reports',
        name: 'reports',
        component: () =>
            import('@/views/reports/reports.vue'),
        meta: {
            pageTitle: 'تقارير الحضور والغياب',
            breadcrumb: [{
                text: 'تقارير الحضور والغياب',
                active: true,
            },],
        },
    },
    {
        path: '/aks/:a/:b/:t',
        name: 'ask',
        component: () =>
            import('@/views/reports/ask.vue'),
            meta: {
                layout: 'full',}
    },
    {
        path: '/reports/reupload',
        name: 'reportsReupload',
        component: () =>
            import('@/views/reports/reupload.vue'),
        meta: {
            pageTitle: 'اجهزة البصمة',
            breadcrumb: [{
                text: 'إعادة رفع التقارير',
                active: true,
            },],
        },
    },
    {
        path: '/reports/freedays',
        name: 'reportsFreedays',
        component: () =>
            import('@/views/reports/freedays.vue'),
        meta: {
            pageTitle: 'الاجازات',
            breadcrumb: [{
                text: 'الاجازات',
                active: true,
            },],
        },
    },
    {
        path: '/reports/ask-ghiab/:date/:number/:id',
        name: 'reportAskGhiab',
        component: () =>
            import('@/views/reports/reportAskGhiab.vue'),
        meta: {
            layout: 'full',
            pageTitle: '',
            breadcrumb: [{
                text: '',
                active: true,
            },],
        },
    },
    {
        path: '/reports/ask-late/:date/:number/:id/:late_time/:time',
        name: 'reportAskLate',
        component: () =>
            import('@/views/reports/reportAskLate.vue'),
        meta: {
            layout: 'full',
            pageTitle: '',
            breadcrumb: [{
                text: '',
                active: true,
            },],
        },
    },
    {
        path: '/reports/ask-late-between/:o/:tw/:th/:fo/:fi',
        name: 'reportAskLateBetween',
        component: () =>
            import('@/views/reports/reportAskLateBetween.vue'),
        meta: {
            layout: 'full',
            pageTitle: '',
            breadcrumb: [{
                text: '',
                active: true,
            },],
        },
    },
    {
        path: '/reports-between',
        name: 'reportsBetween',
        component: () =>
            import('@/views/reports/reportsBetween.vue'),
        meta: {
            pageTitle: 'تقارير الحضور والغياب والتأخر لفترة',
            breadcrumb: [{
                text: 'تقارير الحضور والغياب',
                active: true,
            },],
        },
    },
    {
        path: '/teachers/overview/:id',
        name: 'teacherOverview',
        component: () =>
            import('@/views/teachers/overview.vue'),
        meta: {
            pageTitle: 'نظرة عامة',
            breadcrumb: [{
                text: 'الملف الشخصي للمعلم',
                active: true,
            },],
        },
    },
    {
        path: '/teachers/edit/:id',
        name: 'teacherEdit',
        component: () =>
            import('@/views/teachers/edit.vue'),
        meta: {
            pageTitle: 'تعديل المعلم',
            breadcrumb: [{
                text: 'تعديل المعلم',
                active: true,
            },],
        },
    },
    {
        path: '/general-settings',
        name: 'settingsGeneral',
        component: () =>
            import('@/views/general-settings.vue'),
        meta: {
            pageTitle: 'الإعدادات',
            breadcrumb: [{
                text: 'الإعدادت العامة',
                active: true,
            },],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('@/views/Login.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () =>
            import('@/views/logout.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () =>
            import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/ended',
        name: 'ended',
        component: () =>
            import('@/views/error/ended.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/limit',
        name: 'limit',
        component: () =>
            import('@/views/error/limit.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router